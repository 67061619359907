import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Card } from "@mui/material";
import { Main as Main } from "layouts";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import {paymentSuccessAction} from '../../actions/paymentAction';
import { useDispatch } from "react-redux";
const RazorpaySuccess = () => {

    const dispatch = useDispatch()

    const RazorpayResponse = localStorage.getItem("Razorpayresponse");

    const Data = JSON.parse(RazorpayResponse);

    console.log("DataData", Data);
    const responseData = {
        status: "paid",
        paymentOrderId: Data?.razorpay_order_id,
        paymentId: Data?.razorpay_payment_id,
        signature:Data?.razorpay_signature
    }
   

    useEffect(()=>{
        
        dispatch(paymentSuccessAction(responseData))
    },[])

    const removeData=()=>{
       localStorage.removeItem("Razorpayresponse") 
    }
  return (
    <Main>
      <Container
        maxWidth="md"
        sx={{ mb: 5, mt: 20, p: 5, display: "flex", justifyContent: "center" }}
      >
        <Card
          elevation={5}
          sx={{
            width: 450,
            height: 300,
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <h1>Thank you for payment !</h1>
          <Link to="/">
            <Button variant="contained" onClick={removeData}>Back to Shopping </Button>
          </Link>
        </Card>
      </Container>
    </Main>
  );
};

export default RazorpaySuccess;