import { PLACE_ORDER_URL, PAYMENT_SUCCESS, 
  GET_MY_ORDERS, PAY_U_URL, FETCH_PAYMENT_DETAILS_URL, PREVIEW_ORDER_URL, PAYMENT_STATUS_API, RAZORPAY_STS_URL
 } from "config/services";
import axios from "utils/axios";

class PaymentService {

  fetchPaymentDetails = () =>
    new Promise((resolve, reject) => {
      axios
        .get(FETCH_PAYMENT_DETAILS_URL)
        .then((resp) => {
          if (resp.data) {
            const paymentDetails = resp.data?.data;
            resolve(paymentDetails);
          } else {
            reject(resp.data?.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  previewOrder = (payload) =>
    new Promise((resolve, reject) => {

      console.log("PaymentService.previewOrder() ", payload);
      axios
        .post(PREVIEW_ORDER_URL, payload)
        .then((resp) => {
          resolve(resp?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });


  placeOrder = (newOrder) =>
    new Promise((resolve, reject) => {

      console.log("PaymentService.placeOrder() ", newOrder);
      axios
        .post(PLACE_ORDER_URL, newOrder)
        .then((resp) => {
          resolve(resp?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

  paymentSuccess = (paymentOrderId, paymentId, signature) =>
    new Promise((resolve, reject) => {
      axios
        .put(PAYMENT_SUCCESS, {
          paymentOrderId,
          paymentId,
          signature,
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });


  myOrders = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_MY_ORDERS)
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

  payU = (key, txnid, productinfo, amount, email, firstname, phone, surl, furl, hash) =>
    new Promise((resolve, reject) => {
      axios
        .post(PAY_U_URL, { key, txnid, productinfo, amount, email, firstname, phone, surl, furl, hash })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });


    paymentStatus = (data) =>
    new Promise((resolve, reject) => {
      axios
        .put(PAYMENT_STATUS_API, data)
        .then((response) => {
          resolve(response?.data?.payload);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

    razorpayStatus = (data) =>
      new Promise((resolve, reject) => {
        axios
          .put(RAZORPAY_STS_URL, data)
          .then((response) => {
            resolve(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error?.response?.payload);
          });
      });
    

}

const paymentService = new PaymentService();

export default paymentService;
