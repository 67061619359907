import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import { moneyFormatter } from "utils/formatUtils";
import { PAYMENT_GATEWAY } from "config";
import { GET_PRODUCT_DETAILS } from "config/services";
// import ReviewOrderCheckout from "./ReviewOrder/ReviewOrderCheckout.js"
import { Buffer } from "buffer";
import CryptoJS from "crypto-js";

import { placeOrder } from "actions/paymentAction";
import axios from "axios";
import {
  fetchProductDetails,
  fetchProductDetailsBySKU,
} from "actions/catalogAction";
import CCAvenueRedirectionPage from "views/Payment/CCAvenue/CCAvenueRedirectionPage";
import { processPhonePePayment } from "views/Payment/PhonePe/phonePayUtils";

//import { RazorpayUtils } from "views/Razorpay/Razorpayutils";
//import PhonePe  from "views/PhonePe/PhonePe";

 
import { razorpaySuccessSts } from "../../../actions/paymentAction";
let redirectUrlPhonePe =
  process.env.REACT_APP_PHONEPE_REDIRECT_URL || window.location.hostname;
let redirectUrlCCAvenue = "https://mperito.com/ccavenue<userId>";

// TODO_SP : Need to understand why this is needed and how it can be replaced properly

const centerContact = {
  id: 3,
  name: "SH Service Center",
  email: "center@gmail.com",
  city: "Bangalore",
  state: "Karnataka",
  region: "EAST",
};

function OrderConfirm({
  totalAmount,
  deliveryAddress,
  billingAddress,
  cart,
  cartItems,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShowProductPrice = useSelector((state) => state.app.showProductPrice);
  var razorpayId = useSelector((state) => state?.payment?.paymentOrderId);
  const orderDesc = "Web Order";

  const paymentGateway = useSelector((state) => state.payment?.paymentGateway);
  const paymentDetails = useSelector((state) => state.payment?.paymentDetails);
  console.log("razorpayId", paymentDetails);
  let saltKey = paymentDetails?.paymentGWPayKeySecret;
  let saltKeyIndex = paymentDetails?.paymentGWPayKeyIndex;
  const [redirectData, setRedirectData] = useState(null);

  //Razorpaydata
  var paymentOrderIdstate = useSelector((state) => state?.payment?.paymentOrderId);

  const [orderId, setOrderId] = useState(paymentOrderIdstate);
  const Razorpaydata = useSelector((state) => state.payment.Razorpay);
  console.log("totalAmount02", totalAmount);
  // const [orderId, setOrderId] = useState(paymentOrderIdstate);
  const { user } = useSelector((state) => state.auth);
  // const key = Razorpaydata.gatewayPayKeyId
  //Razorpaydata

  async function handleProceedToPayment() {
    console.log("OrderConfirm: paymentGateway=", paymentGateway);
    console.log("OrderConfirm: paymentGateway=[", paymentGateway,"]");
    console.log("OrderConfirm: paymentGateway=[", paymentGateway,"]");
    switch (paymentGateway) {
      case PAYMENT_GATEWAY.OFFLINE:
        {
          console.log("Payment Gateway: Offline");
          try {
            await dispatch(
              placeOrder(
                centerContact,
                deliveryAddress,
                billingAddress,
                orderDesc
              )
            );
            console.log("Order Placed Successfully");
            navigate("/offline-payment");
          } catch (error) {
            console.log("error", error.response.status);
          }
        }
        break;
        case PAYMENT_GATEWAY.RAZORPAY:
          {
            dispatch(
              placeOrder(
                centerContact,
                deliveryAddress,  
                billingAddress,
                orderDesc
              )
            );
           // await RazorpayUtils(totalAmount, key, orderId );
            const handlePayment = async (totalAmount) => {
              const amountInPaise = Math.round(totalAmount * 100);
              const options = {
                key: Razorpaydata.gatewayPayKeyId,
                amount: amountInPaise,
                currency: "INR",
                name: "",
                description: "Test Transaction",
                order_id: orderId,
                  //  handler: async function (response) {
                  // const data = {
                  //   // orderId: order_id,
                  //   // doneBy: authState.userId,
                  //   // institutionType: authState.userType,
                  //   razorpayPaymentId: response.razorpay_payment_id,
                  //   razorpayOrderId: response.razorpay_order_id,
                  //   razorpaySignature: response.razorpay_signature,
                  // };
          
                  
                handler: function (response ) {
                    let data={
                     status :  "paid",
                     paymentOrderId : response.razorpay_order_id,
                     paymentId : response.razorpay_payment_id,
                     razorpaySignature: response.razorpay_signature
                  }
                 
               
                  dispatch(razorpaySuccessSts(data ));
                  //console.log("responseresponse", response)
                  // alert(`Payment successful: ${response.razorpay_payment_id}`);
                  // setOpen(true);
                  // setOpen(false);
                  // You can handle the response from Razorpay here (e.g., send the payment ID to your server)
                  localStorage.setItem(
                    "Razorpayresponse",
                    JSON.stringify(response)
                  );
                  navigate("/razorpay-success");
                },
                prefill: {
                  name: user?.name,
                  email: user?.email,
                  contact: user?.mobile,
                },
                notes: {
                  address: "",
                },
                theme: {
                  color: "#3399cc",
                },
              };
  
              const rzp1 = new window.Razorpay(options);
              rzp1.open();
            };
            handlePayment(totalAmount);
          }
          break;
        
      case PAYMENT_GATEWAY.PAYU:
        {
          try {
            await dispatch(
              placeOrder(
                centerContact,
                deliveryAddress,
                billingAddress,
                orderDesc
              )
            );
            console.log("Order Placed Successfully");
            navigate("/pay-u");
          } catch (error) {
            console.log("error", error.response.status);
          }
          console.log("Payment Gateway: PayU");
        }
        break;

      case PAYMENT_GATEWAY.PHONEPE:

        {
          console.log("Payment Gateway: PhonePe");
          let redirectUrlPhonePe =  process.env.REACT_APP_PHONEPE_REDIRECT_URL || window.location.hostname;          
          const order = await dispatch(placeOrder(
            centerContact,
            deliveryAddress,
            billingAddress,
            orderDesc,
            redirectUrlPhonePe
          ));

          let paymentUrl = order?.order?.paymentOrderRef?.paymentUrl;
            // console.log("Redirection to PhonePe : ", paymentUrl);
            // console.log("Redirection to PhonePe : ", paymentUrl);
            window.location.href = paymentUrl;

          console.log("Payment Gateway Proceed To Payment: PhonePe");
          // await processPhonePePayment(order, paymentDetails, navigate);          
        };    
        
        break;
 
 
        // TODO: Payment Gateway for CCAvenue is pending to be implemented
        case PAYMENT_GATEWAY.CCAVENUE:
          {
            console.log("Payment Gateway: CCAvenue");
            try {
              const order = await dispatch(
                placeOrder(
                  centerContact,
                  deliveryAddress,
                  billingAddress,
                  orderDesc,
                  redirectUrlCCAvenue                  
                )
              );
 
              console.log("Order Placed Successfully, redirect to ccavenue-pay", order);
              navigate("/payment/ccavenue");
              
 
              // let paymentUrl = order?.order?.paymentOrderRef?.formbody;
              // console.log("Order Placed Successfully, redirect to ccavenue-pay URL", paymentUrl);
              // //window.location.href = paymentUrl;
 
              // const formBody = order?.order?.paymentOrderRef?.formbody;
              // const accessCode = order?.order?.paymentOrderRef?.accessCode;
              // const encodedRequest = order?.order?.paymentOrderRef?.encodedRequest;
 
              // console.log("Order CCAvenue: ", formBody, accessCode, encodedRequest);
              // setRedirectData({ formBody, accessCode, encodedRequest });
 
              // return <CCAvenueRedirectionPage formBody={formBody} accessCode={accessCode} encodedRequest={encodedRequest}/>
                
            } catch (error) {
              console.log("error", error);
            }
          }
 
 
        break;

      default:
        console.log("Payment Gateway is not set from server!!!");
        break;
    }
  }
 
 
 
  
  
  useEffect(() => {
    
  }, [paymentGateway]);
 
  // const orderFun=()=>{
  //   dispatch(
  //     placeOrder(
  //       centerContact,
  //       deliveryAddress,
  //       billingAddress,
  //       orderDesc,
  //       redirectUrlPhonePe
  //     )
  //   );
  // }
  const isReadyForPayment = useSelector(
    (state) => state.checkout?.isReadyForPayment
  );
 
 
  return (
    <Grid mt={2}>
      <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F" }}>
        {/* <CardContent>
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">
 
        </Typography>
      </CardContent>
      <Divider /> */}
 
        <CardContent>
         {isShowProductPrice &&
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              fullWidth
              disabled={!isReadyForPayment}
              size="large"
              type="submit"
              variant="contained"
              sx={{ borderRadius: 30 }}
              onClick={handleProceedToPayment}
            >
              Proceed to Payment
            </Button>
            {/* <Button onClick={orderFun}>order</Button> */}
            {/* <ReviewOrderCheckout/> */}
          </Grid> }
        </CardContent>
        {/* <PhonePe  centerContact={centerContact}
                deliveryAddress={deliveryAddress}
                billingAddress={billingAddress}
                orderDesc={orderDesc}
                paymentDetails={paymentDetails}
                /> */}
      </Card>
    </Grid>
  );
}
 
export default OrderConfirm;