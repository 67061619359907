export const REACT_APP_NAME = process.env.REACT_APP_APP_NAME || "Mperito App";
export const APP_VERSION = "1.0.0";
//export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL || ""; //window.location.hostname;

export const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;

export const ENABLE_REDUX_LOGGER = false;

export const OTP_COUNTER = 30;


export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
};


export const BUSINESS_TYPES = {
    GENERAL: 'general',
    // CLASSES: 'classes',
    // CONSULTANT: 'consulant',
    // SERVICES: 'services',
    // MANUFACTURING: 'manufacturing'
  }
  
  export const USER_ROLES = {
    OWNER: 'OWNER',
    ACCOUNTANT: 'ACCOUNTANT',
    SALES: 'SALES',
    USER: 'USER',
  
  }

const BASE_URL = process.env.REACT_APP_BASE_URL || window.location.hostname;

export const DEFAULT_IMAGES = {
  HERO: "/assets/defaults/hero_1280_720_grey.png",
  PRODUCT: "/assets/defaults/mperito_default_product.png",
  CARD_ITEM: "/assets/defaults/card_item_250_250_grey.png",
};

export const GUARD = {
  GUEST: "GUEST",
  AUTH: "AUTH",
  ADMIN: "ADMIN",
};


export const RZP_KEY = process.env.REACT_APP_RZP_KEY;
export const RZP_NAME = process.env.REACT_APP_RZP_NAME;
export const RZP_DESC = process.env.REACT_APP_RZP_DESC;
export const RZP_LOGO = process.env.REACT_APP_RZP_LOGO;
export const RZP_COLOR = process.env.REACT_APP_RZP_COLOR;
export const RZP_CURRENCY = process.env.REACT_APP_RZP_CURRENCY;


export const STATE_LIST = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const COUNTRY_LIST = [
  "India",
]

export const ADDRESS_TYPE = {
  DEFAULT: 'DEFAULT',
  BILLING: 'BILLING',
  DELIVERY: 'DELIVERY',
  GENERAL: 'GENERAL',
}

export const ADDRESS_STATUS = {
  NEW: 'NEW',
  INFO: 'INFO',
  SELECT: 'SELECT',
  LIST: 'LIST',
}

export const PAYMENT_GATEWAY = {
  OFFLINE: 'OFFLINE',
  PAYU: 'PayU',
  PHONEPE: 'PhonePe',
  RAZORPAY: 'Razorpay',
  CCAVENUE: 'CCAvenue'
}